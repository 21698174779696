// Generated by Framer (2372734)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JjO7L2dvd"];

const variantClassNames = {JjO7L2dvd: "framer-v-1e0gfvl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, tagTitle, width, ...props}) => { return {...props, FTeGC6_iK: tagTitle ?? props.FTeGC6_iK ?? "Tag"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tagTitle?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, FTeGC6_iK, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JjO7L2dvd", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-puSmf", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1e0gfvl", className)} data-framer-name={"Wrap"} layoutDependency={layoutDependency} layoutId={"JjO7L2dvd"} ref={ref} style={{backgroundColor: "var(--token-635b03d7-dcd5-45ce-a6b4-0993a7e6eea4, rgb(0, 208, 102))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-size": "12px", "--framer-font-weight": "500", "--framer-line-height": "1.8em", "--framer-text-color": "var(--extracted-r6o4lv)"}}><motion.strong>Link</motion.strong></motion.p></React.Fragment>} className={"framer-6gq4et"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"OXIaBecDk"} style={{"--extracted-r6o4lv": "var(--token-0109aa55-a8db-4917-9953-a1fb1bfad426, rgb(255, 255, 255)) ", "--framer-paragraph-spacing": "0px"}} text={FTeGC6_iK} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-puSmf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-puSmf .framer-1cp8m3u { display: block; }", ".framer-puSmf .framer-1e0gfvl { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; padding: 10px 20px 10px 20px; position: relative; width: min-content; }", ".framer-puSmf .framer-6gq4et { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-puSmf .framer-1e0gfvl { gap: 0px; } .framer-puSmf .framer-1e0gfvl > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-puSmf .framer-1e0gfvl > :first-child { margin-left: 0px; } .framer-puSmf .framer-1e0gfvl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 41
 * @framerIntrinsicWidth 61
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"FTeGC6_iK":"tagTitle"}
 */
const FramerDX1CrMiNw: React.ComponentType<Props> = withCSS(Component, css, "framer-puSmf") as typeof Component;
export default FramerDX1CrMiNw;

FramerDX1CrMiNw.displayName = "Tag / Green";

FramerDX1CrMiNw.defaultProps = {height: 41, width: 61};

addPropertyControls(FramerDX1CrMiNw, {FTeGC6_iK: {defaultValue: "Tag", displayTextArea: false, placeholder: "", title: "Tag Title", type: ControlType.String}} as any)

addFonts(FramerDX1CrMiNw, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/DX1CrMiNw:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf", weight: "500"}])